<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="2"/>

      <!-- 标题 -->
      <div class="writer-box-content-title" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        <div class="writer-box-content-title-input d-flex flex-between flex-y-center">
          <div class="d-flex flex-y-center" style="width: auto;margin-right: 1rem;flex:1;">
            <div style="" class="image-switcher" @click="focusTitle"></div>
            <el-input class="custom-title" ref="customtitle" v-model="story.title" @blur="handleUpdateTitle" placeholder="起一个炸裂吸睛的标题吧~">
            </el-input>
          </div>
          <!-- <span style="background: #F2F3FF;height:28px;line-height:28px;font-weight:normal;border-radius: 3px;padding: 0 .5rem;font-size: 12px;color: #0052D9;">双男主短篇-正文-欢脱风 v1.0</span> -->
        </div>
        <div class="writer-box-content-title-tag">
          <span class="writer-box-content-title-tag-tag">{{story.articleTypeLabel}}</span>
          <span class="writer-box-content-title-tag-tag" style="margin: 0;">{{story.writingPerspectiveLabel}}</span>
          <!-- <span class="writer-box-content-title-tag-tag flex-y-center" style="background-color: transparent;display: inline-flex;">已自动保存 <img src="@/assets/writer-saved.png" alt="" style="width: .8rem;" /></span> -->
        </div>
      </div>

      <div class="section-box" @click="sectionListDialog = true" v-if="currentStoryOutline.id">
        <span>{{'第'+ currentStoryOutline.outlineNumber+'章'}}</span>
        <span>
          <el-select :class="['custom-select', 'current-select']" :disabled="isLoading"
              v-model="currentStoryOutline.contentVersionId"
              filterable
              size="mini"
              placeholder="版本"
              style="width: 110px;"
              >
            <el-option
              v-for="itemVersion in currentStoryOutline.storyContentVersions"
              @click.native.stop="contentChange(itemVersion)"
              :key="itemVersion.id"
              :label="'本章版本 ' + itemVersion.version"
              :value="itemVersion.id"
            />
          </el-select>
          <el-button class="button" :disabled="isLoading"><i class="icon el-icon-sort"></i>切换章节</el-button>
        </span>
      </div>

      <div class="writer-box-content-work d-flex" style="padding-bottom: 1rem;">
        <div class="writer-box-content-work-outline hidden-xs-only" style="display: flex;flex-direction: column;">
            <div class="" style="font-weight: 400;
                                  font-size: 12px;
                                  color: rgba(0,0,0,0.6);
                                  line-height: 24px;">
              <span>{{'共 ' + storyOutlines.length + ' 章'}}</span>
              <span>{{ ' 全文字数：' + storyContentFullCount}}</span>
            </div>
            <div class="outline-list-box" style="flex: 1;">
              <el-card @click.native="outlineItemClick(item)" shadow="hover" v-for="(item, index) in storyOutlines" :key="index" class="my-1 outline-item"
              :class="[{'outline-active': item.outlineNumber == currentOutlineNumber}, index == 0 || (index > 0 && storyOutlines[index-1].genFlag) ? 'pointer' : 'not-allowed']">
                <div class="d-flex flex-between flex-y-center writer-box-content-work-outline-title" :class="{'writer-box-content-work-outline-title-disabled': !item.genFlag}">
                  <span>{{item.outlineNumber}}</span>
                  <span v-if="item.contentVersionId>0 && item.outlineNumber == currentOutlineNumber">
                    <el-select :class="['custom-select', item.outlineNumber == currentOutlineNumber ? 'current-select' : '']" :disabled="(item.outlineNumber != currentOutlineNumber) || isLoading"
                        v-model="item.contentVersionId"
                        filterable
                        size="mini"
                        placeholder="版本">
                      <el-option
                        v-for="itemVersion in item.storyContentVersions"
                        @click.native.stop="contentChange(itemVersion)"
                        :key="itemVersion.id"
                        :label="'本章版本 ' + itemVersion.version"
                        :value="itemVersion.id"
                      />
                    </el-select>
                  </span>
                </div>
                <div class="d-flex flex-between writer-box-content-work-outline-time" :class="{'writer-box-content-work-outline-time-disabled': !item.genFlag}">
                  <span>{{item.createTime}}</span>
                  <span>{{(item.wordCount?item.wordCount:0) + " 字"}}</span>
                </div>
              </el-card>
            </div>
            <WriteTips />
        </div>

        <div class="writer-box-content-work-editor">
          <!--  -->
          <el-input
            @cut.native="changeInput"
            @paste.native="changeInput"
            @change="changeInput"
            v-if="!isSkeleton" :disabled="!storyContent" id="msgbox" type="textarea" v-model="storyContent" style="font-weight: 600;
                                                                  font-size: 16px;
                                                                  color: #000000;
                                                                  line-height: 24px;">

          </el-input>
          <LlmSkeleton v-else />
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="writer-box-footer">
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="startGenStoryContent(0)" :loading="isLoading" :disabled="isLoading" v-if="storyOutlines.length>0 && !storyOutlines[currentOutlineNumber-1].genFlag">生成本章</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="default" round icon="el-icon-refresh" @click="startGenStoryContent(1)" :loading="isLoading" :disabled="isLoading" v-if="storyOutlines.length>0 && storyOutlines[currentOutlineNumber-1].genFlag">重新生成本章</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="outlineItemClick(storyOutlines[currentOutlineNumber])" :disabled="isLoading" v-if="storyOutlines.length>0 && currentOutlineNumber < storyOutlines.length
         && storyOutlines[currentOutlineNumber-1].genFlag">下一章</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="doPreviewContent" :disabled="isLoading" v-if="storyOutlines.length>0 && !storyOutlines.some(item => !item.genFlag)">预览全文</el-button>
      </span>
    </div>

    <el-dialog
      class="custom-dialog"
      top="10vh"
      title=""
      :visible.sync="showOutlineDialog">
      <el-form :model="currentStoryOutline" :rules="rules" label-position="top" class="custom-form">
        <el-input type="hidden" placeholder="" v-model="currentStoryOutline.id"></el-input>
        <div class="" style="font-weight: 600;margin-top: -24px;
                          font-size: 20px;
                          color: rgba(0,0,0,0.9);">
          本章大纲
        </div>
        <div class="" style="border-radius: 20px; margin-top: 1rem; padding: 1rem;
                              border: 1px solid rgba(0,82,217,0.27);">
          <div class="" style="font-weight: 600; margin-bottom: 2rem;margin-left: 1rem;
                            font-size: 16px;
                            color: rgba(0,0,0,0.9);">
            本章情节
          </div>
          <el-form-item label="">
            <el-input type="textarea" placeholder="大纲内容"
            v-model="currentStoryOutline.content" :rows="10" class="no-border-input"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input type="textarea" placeholder="请输入本章写作要求，例如可以指定哪部分要详细展开"
            v-model="currentStoryOutline.contentRequired" class="custom-textarea"></el-input>
          </el-form-item>
        </div>

        <div style="display: flex;align-items: center;margin-top: 1rem;">
          <el-switch style="" class="mly-switch" style="margin-right: 4px;"
            v-model="aiMasterFlag"
            active-color="#0052d9"
            active-text="AI总编提质"
            inactive-text="">
          </el-switch>
          <el-tooltip placement="top">
            <div slot="content">
              让AI总编指导AI生成正文，可以提高质量，但会额外消耗些许能量
            </div>
            <div style="display: flex; align-items: center; justify-content: center; height: 14px; margin-bottom:-2px">
              <img style="height: 14px;" src="@/assets/tishi-icon.png" />
            </div>
          </el-tooltip>
        </div>

        <div style="margin-top: 1rem; text-align: center;">
          <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="startGenStoryContent(3)" :loading="isLoading" :disabled="isLoading" v-if="storyOutlines.length>0 && !storyOutlines[currentOutlineNumber-1].genFlag">生成本章</el-button>
          <el-button type="primary" @click="startGenStoryContent(3)" v-else>重新生成本章</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog
    :visible.sync="sectionListDialog"
    :with-header="false"
    class="custom-dialog">
      <div class="writer-box-content-work-outline">
          <div class="" style="font-weight: 400;
                                font-size: 12px;
                                color: rgba(0,0,0,0.6);
                                line-height: 24px;">
            <span>{{'共 ' + storyOutlines.length + ' 章'}}</span>
            <span>{{ ' 全文字数：' + storyContentFullCount}}</span>
          </div>
          <div class="outline-list-box">
            <el-card @click.native="outlineItemClick(item)" shadow="hover" v-for="(item, index) in storyOutlines" :key="index" class="my-1 outline-item"
            :class="[{'outline-active': item.outlineNumber == currentOutlineNumber}, index == 0 || (index > 0 && storyOutlines[index-1].genFlag) ? 'pointer' : 'not-allowed']">
              <div class="d-flex flex-between flex-y-center writer-box-content-work-outline-title" :class="{'writer-box-content-work-outline-title-disabled': !item.genFlag}">
                <span style="flex:1">{{'第'+item.outlineNumber+'章'}}</span>
              </div>
              <div class="d-flex flex-between writer-box-content-work-outline-time" :class="{'writer-box-content-work-outline-time-disabled': !item.genFlag}">
                <span>{{item.createTime}}</span>
                <span>{{(item.wordCount?item.wordCount:0) + " 字"}}</span>
              </div>
            </el-card>
          </div>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import StepsWriter from '@/components/Steps4IdeaWriter'
import HeadNavWriter from '@/components/HeadNavWriter'
import LlmSkeleton from '@/components/LlmSkeleton'
import WriteTips from '@/components/WriteTips'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryOutlineApi from '@/api/storyoutline'
import * as StoryContentApi from '@/api/storycontent'
import * as StoryOutlineVersionApi from '@/api/storyoutlineversion'
import sse from '@/utils/llmstream'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    LlmSkeleton, HeadNavWriter, StepsWriter,WriteTips
  },
  data() {
    return {
      aiMasterFlag: true,
      showOutlineDialog: false,
      enums: [],
      titleInputValue: '',
      currentStoryOutline: {
        id: '',
        content: '',
        contentRequired: '',
      },
      isLoading: false,
      isSkeleton: false,
      storyContentFullCount: '',
      storyContent: '',
      // storyContents: [],
      storyOutlines: [],
      currentOutlineNumber: 1,
      rules: {
        content: [
          { required: true, message: '请选择故事章纲', trigger: 'blur' }
        ],
        contentRequired: [
          { required: true, message: '请输入写作要求', trigger: 'blur' }
        ]
      },
      sectionListDialog: false
    }
  },
  computed: {
  	...mapState(['user', 'story', 'storyContents', 'dialogShowCostomContactFlag']),
  },
  mounted() {
    this.handlePageStoryOutline(0)
    console.log('buttonsize', this.$root.buttonSize)
  },
  methods: {
    ...mapMutations(['setStory', 'setStoryContents', 'setDialogShowCostomContactFlag']),
    outlineItemClick(item) {
      // console.log('outlineItemClick', item);
      // 上一级没有生成正文，禁止点击
      if((item.outlineNumber > 1 && !(this.storyOutlines[item.outlineNumber - 2].genFlag)) || this.isLoading){
        return false
      }
      this.currentOutlineNumber = item.outlineNumber
      this.currentStoryOutline = item
      if(this.currentOutlineNumber <= this.storyContents.length){
        this.storyContent = this.storyContents[this.currentOutlineNumber-1].content
      }else{
        this.storyContent = ''
      }
      this.sectionListDialog = false
      if(!item.genFlag){
        if(this.user.desktopFlag){
          this.startGenStoryContent(0)
        }
      }
    },
    contentChange(item){
      // console.log('contentChange', item.content);
      this.storyContent = item.content
      this.storyContents[this.currentOutlineNumber-1].content = item.content
      this.storyContents[this.currentOutlineNumber-1].contentVersionId = item.id
      // 更新 content version id
      this.setStoryContents(this.storyContents)

      StoryContentApi.update({id: this.storyContents[this.currentOutlineNumber-1].id,
        storyOutlineId: this.currentStoryOutline.id,
        contentVersionId: item.id, content: item.content}).then(res => {

      })
    },
    changeInput(event){
      // console.log('changeInput', event);
      this.handleUpdateOutlineContent()
    },
    handleUpdateOutlineContent() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if (!this.storyContent || !this.storyContent.trim()) {

          return false;
      }

      this.storyContents[this.currentOutlineNumber-1].content = this.storyContent
      this.setStoryContents(this.storyContents)

      let storyContentCurrentVersionId = this.storyContents[this.currentOutlineNumber-1].contentVersionId
      // console.log('currentOutlineNumber', this.currentOutlineNumber);
      // console.log('storyContentCurrentVersionId', storyContentCurrentVersionId);
      // console.log('storyOutline', this.storyOutlines[this.currentOutlineNumber-1]);
      // console.log('storyContents', this.storyContents);
      // console.log('storyContent', this.storyContent);

      this.storyOutlines[this.currentOutlineNumber-1].storyContentVersions.forEach((item,index) => {
        if(item.id==storyContentCurrentVersionId){
          item.content = this.storyContent
        }
      })

      StoryContentApi.update({id: this.storyContents[this.currentOutlineNumber-1].id,
        storyOutlineId: this.currentStoryOutline.id,
        contentVersionId: storyContentCurrentVersionId,
        content: this.storyContent}).then(res => {

      })
    },
    doPreviewContent() {
      this.handleUpdateStepNumber()
      let texts = []
      this.storyContents.forEach((item, index) => {
        texts.push(item.content)
      })
      ArticleApi.update({id: this.story.id, articleState: 'COMPLETED',
          content: texts.join('\n\n')}
      ).then(res => {
        this.story.content = texts.join('\n\n')
        this.setStory(this.story)
        this.$router.push('/workspace/genbyidea4');
      });
    },
    handleStoryContentMsg(msg){
      // console.log('element', msg);
      this.storyContent = msg
      this.isSkeleton = false
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    startGenStoryContent(from) {
      if(from === 0){// 生成本章 生成 ”弹窗“
        this.showOutlineDialog = true
        return false
      }else if(from === 1){// dialog 重新生成 ”弹窗“
        this.showOutlineDialog = true
        return false
      }else if(from === 2){// 生成下一章
        if(this.currentOutlineNumber < this.storyOutlines.length){
          this.currentOutlineNumber = this.currentOutlineNumber + 1
          this.currentStoryOutline = this.storyOutlines[this.currentOutlineNumber-1]
        }
      }else if(from === 3){// 重新生成 ”执行“
        this.showOutlineDialog = false
        // 生成单章正文
        this.currentStoryOutline.fromType = 'genbyidea3';
        StoryOutlineApi.update(this.currentStoryOutline).then(res => {
          let result = res.data.data;
          if(res.data.code == 0){

          }else{
            this.$message.error(res.data.msg);
          }
        })
      }

      let params = Object.assign({}, this.story)
      params.articleId = this.story.id
      params.aiMasterFlag = this.aiMasterFlag
      params.storyOutlineId = this.currentStoryOutline.id
      params.storyElement = this.story.storyElement
      let contentCount = this.storyContents.length
      let currentNumber = this.currentOutlineNumber
      let currentNumberD1 = currentNumber-1
      console.log('startGenStoryContent', currentNumber, currentNumberD1, contentCount);
      if(currentNumber > 1 && currentNumberD1 <= contentCount){
        params.storyContentPre = this.storyContents[currentNumberD1-1].content
      }else{
        params.storyContentPre = ''
      }

      params.storyOutline = this.currentOutlineNumber + '\n' + this.currentStoryOutline.content
      params.storyOutlineRequired = this.currentStoryOutline.contentRequired

      // valid
      console.log(`startGenStoryContent valid! `, this.story, params)
      if (!params.storyElement) {
          this.$message.error('故事元素丢失');
          return false;
      }
      if (!this.currentOutlineNumber || this.currentOutlineNumber == 0) {
          this.$message.error('故事章纲序号丢失');
          return false;
      }
      if (!params.storyOutline) {
          this.$message.error('故事章纲内容丢失');
          return false;
      }
      if (!params.storyContentPre) {
        params.storyContentPre = '无'
      }
      if (!params.storyOutlineRequired) {
        params.storyOutlineRequired = '无'
      }
      this.isSkeleton = true
      this.isLoading = true
      // sse end
      let workflowId = "1805501901924712449"

      sse(workflowId, params, this.handleStoryContentMsg).then((result)=>{
        // console.log('result', result);
        //AI结束，处理逻辑
        this.isLoading = false
        this.isSkeleton = false
        this.handlePageStoryOutline(-1)
        if(this.currentOutlineNumber == this.storyOutlines.length){
          this.handleUpdateStepNumber()
        }
        this.$refs.headeNvWriterRef.syncUser()
      }).catch(error => {
        // 处理错误
        console.error(error);
        this.storyContent=this.storyContents[this.currentOutlineNumber-1]?this.storyContents[this.currentOutlineNumber-1].content:''
        this.isLoading = false;
        this.isSkeleton = false;
      })
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    focusTitle() {
      let input = this.$refs.customtitle
      input.focus();
      const length = input.value.length;
      if (length > 0) {
        input.setSelectionRange(length, length);
      }
    },
    handlePageStoryOutline(flag){
      StoryOutlineApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyOutlines = result.records;
            this.currentStoryOutline = this.storyOutlines[this.currentOutlineNumber-1];
            // 大纲初始化，之后正文初始化
            this.handlePageStoryContent()
            console.log('handlePageStoryOutline flag' , flag)
            if(flag == 0){
              console.log('handlePageStoryOutline genFlag' , this.storyOutlines[0].genFlag)
              // 页面初始化 判断第一章是否生成正文，否：自动生成正文
              if(!this.storyOutlines[0].genFlag){

                if(this.user.desktopFlag){
                  this.startGenStoryContent(0)
                }
              }else{
                this.isLoading = false
                this.isSkeleton = false
              }
            }else if(flag == 1){
              // 重新生成

            }
          }else{
            console.error('无大纲')
            this.isLoading = false
            this.isSkeleton = false
          }
        }
      })
    },
    handleUpdateTitle() {
      ArticleApi.update({id: this.story.id, title: this.story.title}).then(res => {

      });
    },
    handleUpdateStepNumber() {// 点击预览全文 和 全部正文生成完
      ArticleApi.update({id: this.story.id, stepNumber: 4, articleState: 'COMPLETED', stepStatus: 'success'}).then(res => {
        this.story.stepNumber = 4
        this.story.stepStatus = 'success'
        this.story.articleState = 'COMPLETED'
        console.log('handleUpdateStepNumber')
        this.setStory(this.story)
      });
    },
    handleEnum() {
      EnumApi.list().then(res => {
        this.enums = res.data.data;
      })
    },
    handlePageStoryContent(){
      StoryContentApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            //补充字数
            result.records.forEach((item, index) => {
              if(this.storyOutlines[index]){
                this.storyOutlines[index].wordCount = item.content.length
              }
            })
            // console.log('story word count', result.records);
            this.setStoryContents(result.records)
            this.storyContentFullCount = result.records.map(element => element.content.toString().length)
               .reduce((total, length) => total + length, 0);
            this.storyContent = this.storyContents[this.currentOutlineNumber-1].content;
            this.isLoading = false
            this.isSkeleton = false
          }
        }
      })
    },
    nextSection(){

    }
  }
}
</script>

<style scoped lang="scss">
$lightBgColor: #E5E7FC;
$lightBgColor2: #CDD1FF;
$mainTextColor: #0052D9;
.writer-box {
  &-content {
    padding-bottom: 2rem;
    &-work {
        height: calc( 100% - 8.8rem );
      &-outline {
        height: 100%;
        width: 20rem;
        overflow-y: scroll; /* 启用垂直滚动条 */
        overflow-x: hidden; /* 隐藏水平滚动条 */
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: $mainTextColor;
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
        &-time {
          font-weight: 400;
          font-size: .8rem;
          color: rgba(0,0,0,0.6);
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
      }
      &-editor {
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      &-tag {
        font-size: 12px;
        color: #242424;
        margin: 0.5rem 0;
        &-tag {
          background: $lightBgColor;
          border-radius: 12px;
          font-weight: 400;
          color: #242424;
          padding: .25rem 1rem;
          line-height: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
.outline-list-box {
  height: calc( 100% - 15.5rem);
  overflow-y: scroll;
  margin-bottom: .5rem;
}
.story-content {
  min-height: 20rem;
}
.outline-active {
  background: $lightBgColor;
  color: $mainTextColor;
}
.outline-item:hover {
  background: $lightBgColor;
  color: $mainTextColor;
}
/deep/ .writer-box-content-work-outline .el-card {
  margin-right: 1rem;
  overflow-y: hidden !important;
  border-radius: 10px;
}
/deep/ .writer-box-content-work-outline .el-card .el-card__body {
  padding: 0 .5rem;
}

/* 去除 el-select 组件的边框 */
/deep/ .custom-select .el-input__inner {
    border: none; /* 去除边框 */
    outline: none; /* 去除聚焦时的轮廓 */
    box-shadow: none; /* 去除阴影 */
    background: transparent;
    color: $mainTextColor;
    border-radius: 30px;
    // height: 22px;
    // line-height: 22px;
}
/deep/ .current-select .el-input__inner {
    background: $lightBgColor2;
}
/deep/ .current-select .el-input__inner::placeholder {
    color: $mainTextColor; /* 设置你想要的颜色 */
}
/deep/ .current-select.el-select .el-input .el-select__caret {
  color: $mainTextColor;
}

/* 如果你还想去除悬浮状态的边框 */
/deep/ .custom-select .el-input__inner:hover {
    border-color: transparent; /* 边框颜色设置为透明 */
    background: #F2F3FF;
}
/deep/ .custom-form .el-dialog__body {
  padding: 0 3rem 2rem 3rem;
}
/deep/ .custom-title .el-input__inner {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    color: #000000;
}
/deep/ .no-border-input .el-textarea__inner {
    border: none;
    outline: none;
    box-shadow: none;
}
/deep/ .no-border-input .el-textarea__inner:hover,
/deep/ .no-border-input .el-textarea__inner:focus {
    border-color: transparent;
}
/deep/ .el-dialog {
    border-radius: 20px; /* 调整圆角大小 */
}
/deep/ .custom-textarea .el-textarea__inner {
  background: #FAFAFD;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid rgba(0,82,217,0.26);
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.9);
}
/* CSS样式 */
.image-switcher {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../../assets/writer-bianji.png'); /* 默认状态下的图片 */
  background-size: cover;
  transition: background-image 0.3s; /* 平滑过渡效果 */
}

/* 鼠标悬浮时显示的图片 */
.image-switcher:hover {
  background-image: url('../../assets/writer-bianji-2.png'); /* 默认状态下的图片 */
}

/* 鼠标点击时显示的图片，注意 :active 选择器可能需要与 :hover 结合使用 */
.image-switcher:active {
  background-image: url('../../assets/writer-bianji-3.png'); /* 默认状态下的图片 */
}

.section-box {
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-flow: nowrap row;
  padding: 0.5rem 1rem;
  background: $lightBgColor;
  border-radius: 20px;
  margin-bottom: 1rem;

  .button {
    display: inline-block;
    background: #366EF4;
    font-size: 12px;
    border-radius: 50px;
    border: 0;
    padding: 0 15px;
    height: 28px;
    line-height: 28px;
    color: #fff;
    margin-left: 10px;
    font-weight: normal;

    .icon {
      transform: rotate(90deg);
      margin-right: 5px;
    }

    &.is-disabled {
      color:#C0C4CC;
      background: #FFFFFF;
    }
  }
}

.mly-switch {

  /deep/ .el-switch__label.is-active{
    color: rgba(0,0,0,0.5);
    white-space: nowrap;
  }
  /deep/ .el-switch__label {
    white-space: nowrap;
    color: rgba(0,0,0,0.5);
    span {
      font-size: 12px;
    }
  }
  /deep/ .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    border-radius: 10px !important;
  }
  /deep/ .el-switch__core:after {
    top: 0 !important;
    left: 17px !important;
    width: 12px !important;
    height: 12px !important;
  }
  /deep/ .el-switch.is-checked .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    left: 0 !important;
    border-radius: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .section-box {
    display: flex;
  }
  .writer-box-content-work {
    height: calc( 100% - 15rem);
  }
}
</style>
